import { useFlags } from '@atlaskit/flag';
import { IOrder, IOrderProductDetail, IOrdersQueryOptions } from 'core/api/orders/orders-api-interface';
import OrdersApiService from 'core/api/orders/orders-api.service';
import { CustomerStatus, getCustomerStatusNameFromKey } from 'core/constants/customer-status';
import { getAidLengthNameFromKey } from 'core/constants/hearing-aid-lengths';
import { getAidReceiverGainNameFromKey } from 'core/constants/hearing-aid-receiver-gains';
import { getAidTypeNameFromKey } from 'core/constants/hearing-aid-types';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { UsersSlice } from 'modules/users/users-slice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ParamKeyValuePair, useNavigate, useSearchParams } from 'react-router-dom';
import SkeletonElement from 'shared/components/layout/skeleton-element';
import PageHeader from 'shared/components/page-header/page-header';
import dayjs from 'dayjs';
import { StringIndexable } from 'core/utilities/interface-helpers';
import { useAuthState } from 'core/providers/AuthProvider';
import { hasPermission } from 'core/utilities/permission-helpers';
import { Permissions } from 'core/constants/permissions';
import SharedFilters, { ISharedFilterField } from 'shared/components/filters/filters';
import { FilterType } from 'core/enums/filter-type';
import { isNotNullOrEmpty } from 'core/utilities/null-checkers';

interface IOrdersByStatus {
  [key: string]: IOrder[];
}

const FittingDashboard = () => {
  const [allOrders, setAllOrders] = useState<IOrder[]>([]);
  const [ordersByStatus, setOrdersByStatus] = useState<IOrdersByStatus>();
  const flags = useFlags();
  const navigate = useNavigate();
  const users = useSelector(UsersSlice.selectAll);
  const dispensers = users.filter((user) => user.roles.includes('hearingSpecialist'));
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData, userPermissions } = useAuthState();
  const fullAccess = hasPermission(Permissions.FITTING_DASHBOARD_FULL, userPermissions);
  const [filterValues, setFilterValues] = useState<StringIndexable>({});

  const dispenserSelection = dispensers.map((d) => ({
    value: d.uid,
    label: d.fullName,
  }));

  const filters: ISharedFilterField[] = [
    {
      label: 'Date from',
      key: 'orderDateFrom',
      filterType: FilterType.DatePicker,
    },
    {
      label: 'Date to',
      key: 'orderDateTo',
      filterType: FilterType.DatePicker,
    },
    {
      label: 'Dispenser',
      key: 'selectedDispenser',
      filterType: FilterType.Select,
      options: dispenserSelection,
    },
  ];

  const statuses = useMemo(
    () => [
      CustomerStatus.AWAITING_FITTING_BOOKING,
      CustomerStatus.AWAITING_FITTING_BOOKING_SPECIAL,
      CustomerStatus.AWAITING_FITTING_BOOKING_WARRANTY,
      CustomerStatus.FITTING_BOOKED,
      CustomerStatus.FITTING_CANCELLED,
      CustomerStatus.FITTING_CANCELLED_REARRANGE,
      CustomerStatus.AWAITING_IMPRESSIONS_BOOKING,
      CustomerStatus.AWAITING_FINANCE_APPROVAL,
      CustomerStatus.FINANCE_DECLINED,
      CustomerStatus.AWAITING_ME2_OUTCOME,
      CustomerStatus.ME2_DECLINED,
      CustomerStatus.RETURNED_AWAITING_REFUND,
    ],
    []
  );

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createOrderSubscription = useCallback(() => {
    const handleSnapshot = (querySnapshot: QuerySnapshot<IOrder>) => {
      const orders: IOrder[] = [];
      querySnapshot.forEach((snap) => {
        const data = snap.data();
        if (orders.some((order) => order.customer.uid === data.customer.uid)) {
          return;
        }
        orders.push(data);
      });
      setAllOrders(orders);
    };

    const queries: IOrdersQueryOptions = {
      customerStatuses: statuses,
    };

    if (!fullAccess) {
      queries.dispenser = userData?.uid;
    }
    return OrdersApiService.subscribeToOrders(handleSnapshot, handleSubscriptionError, queries, ['createdAt']);
  }, [statuses, fullAccess, handleSubscriptionError, userData?.uid]);

  useEffect(() => {
    setFilterValues({});
    searchParams.forEach((value, key) => {
      setFilterValues((prevState) => {
        return { ...prevState, [key]: value };
      });
    });
  }, [searchParams]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    unsubscribe = createOrderSubscription();
    return () => {
      unsubscribe();
    };
  }, [createOrderSubscription]);

  useEffect(() => {
    const ordersByStatus: IOrdersByStatus = {};
    allOrders
      .filter((order) =>
        Object.keys(filterValues)
          .filter((key) => isNotNullOrEmpty(filterValues[key]))
          .every((key) => {
            const value = filterValues[key];
            if (!value) {
              return true;
            }
            switch (key) {
              case 'orderDateFrom':
                return dayjs(value).toDate() < order.createdAt.toDate();
              case 'orderDateTo':
                return dayjs(value).set('hour', 23).set('minute', 59).toDate() > order.createdAt.toDate();
              case 'selectedDispenser':
                return value === order.dispenser.uid;
              default:
                return value === order[key];
            }
          })
      )
      .forEach((order) => {
        const currentStatusData = ordersByStatus[order.customer.status];
        if (currentStatusData) {
          currentStatusData.push(order);
        } else {
          ordersByStatus[order.customer.status] = [order];
        }
      });
    setOrdersByStatus(ordersByStatus);
  }, [allOrders, filterValues]);

  const getAidDetail = (aids: (IOrderProductDetail | undefined)[]) => {
    const filtered = aids.filter((aid) => aid);
    if (filtered.length === 0) {
      return 'No aid';
    }

    const firstAid = filtered[0];
    return `${getAidTypeNameFromKey(firstAid!.type)} - ${getAidReceiverGainNameFromKey(
      firstAid!.gain
    )} - ${getAidLengthNameFromKey(firstAid!.length)}`;
  };

  const getColumnContent = (status: string) => {
    if (!ordersByStatus) {
      return <SkeletonElement width='100%' height='105px' />;
    }

    const columnOrders = ordersByStatus[status];

    if (!columnOrders) {
      return <></>;
    }

    return columnOrders.map((order) => (
      <div
        key={order.uid}
        className='p-2 shadow-sm rounded-md bg-blue-50 mb-2 last:mb-0 body-02 cursor-pointer hover:bg-blue-100 transition ease-in-out'
        onClick={() => navigate(`/customers/${order.customer.uid}?tab=orders`)}
      >
        <div className='body-03 opacity-40 mb-2 text-blue-700 flex justify-between items-center'>
          <p>{dayjs(order.createdAt.toDate()).format('DD/MM/YYYY')}</p>
          <p>{order.dispenser.fullName}</p>
        </div>
        <p className='font-semibold'>{order.customer.fullName}</p>
        <p className='body-03 mb-2'>{order.customer.address ? order.customer.address.formatted_address : 'Unknown'}</p>
        <p>{getAidDetail([order.left, order.right])}</p>
      </div>
    ));
  };

  const paramChanged = (key: string, value: string) => {
    if (!value) {
      searchParams.delete(key);
      setSearchParams(searchParams, { replace: true });
    } else {
      const newValues: ParamKeyValuePair[] = [];
      searchParams.forEach((currentVal, currentKey) => {
        if (currentKey !== key) {
          newValues.push([currentKey, currentVal]);
        }
      });
      newValues.push([key, value]);
      setSearchParams(newValues);
    }
  };

  return (
    <>
      <PageHeader title='Fitting Dashboard' />
      {fullAccess && (
        <div className='z-20'>
          <SharedFilters
            filterInputs={filters}
            filterValues={filterValues}
            changeHandler={paramChanged}
            gridStyles='grid-cols-4 gap-6'
          />
        </div>
      )}
      <div className='bg-white rounded-md flex shadow-md mt-4 overflow-x-auto h-full'>
        {statuses.map((status) => (
          <div className='min-w-[260px] border-r last:border-r-0 overflow-y-auto hide-scrollbar' key={status}>
            <div className='h-[60px] p-2 flex flex-col justify-between border-b sticky top-0 bg-white z-10 overflow-x-auto break-keep whitespace-nowrap hide-scrollbar'>
              <p className='body-02 font-semibold capitalize'>{getCustomerStatusNameFromKey(status)}</p>
              <p className='body-03'>
                {ordersByStatus ? `${ordersByStatus[status] ? ordersByStatus[status].length : 0}` : ''}
              </p>
            </div>
            <div className='p-2'>{getColumnContent(status)}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FittingDashboard;
