import { IAppointment } from 'core/api/appointments/appointments-api-interface';
import AppointmentsApiService from 'core/api/appointments/appointments-api.service';
import { AppointmentType, getAppointmentTypeNameFromKey } from 'core/constants/appointment-type';
import { CustomerStatuses } from 'core/constants/customer-status';
import { getRandomColor } from 'core/utilities/color-helpers';
import { USStringToUKString } from 'core/utilities/date-helpers';
import { QuerySnapshot } from 'firebase/firestore';
import { useCallback, useState } from 'react';
import PageHeader from 'shared/components/page-header/page-header';
import { ISharedTableCustomCellTemplate } from 'shared/components/table/table-interface';
import ReportBase, { IReportBaseTableRows } from '../report-base/report-base';
import ReportBreakdownCustomCell, {
  IReportBreakdownCustomCell,
} from '../report-breakdown-custom-cell/report-breakdown-custom-cell';
import { getLeadTypeNameFromKey } from 'core/constants/lead-type';
import Select from '@atlaskit/select';
import { SelectOption } from 'core/utilities/interface-helpers';

const HearingTestOutcome = () => {
  const [type, setType] = useState<string>('organiser');
  const [columnLabel, setColumnLabel] = useState<string>('Organiser');

  const getCustomerListMetaElement = (app: IAppointment) => {
    return (
      <div>
        <p className='font-semibold'>{getAppointmentTypeNameFromKey(app.type)}</p>
        <p className='body-02'>
          {USStringToUKString(app.date)} at {app.start}
        </p>
      </div>
    );
  };

  const processRows = useCallback(
    async (querySnapshot: QuerySnapshot<IAppointment>) => {
      const rows: IReportBaseTableRows<IReportBreakdownCustomCell>[] = [];
      querySnapshot.forEach((appointment) => {
        const { customer, organiser } = appointment.data();
        let comparisonUid = '';
        let label = '';
        if (type === 'organiser') {
          comparisonUid = organiser.uid;
          label = organiser.fullName;
          setColumnLabel('Organiser');
        } else {
          comparisonUid = customer.leadType;
          label = getLeadTypeNameFromKey(customer.leadType);
          setColumnLabel('Lead Type');
        }

        const rowIndex = rows.findIndex((row) => row.key === comparisonUid);
        const customerListRow = {
          metaElement: getCustomerListMetaElement(appointment.data()),
          customer,
        };
        if (rowIndex !== -1) {
          rows[rowIndex].data.count += 1;
          const typeIndex = rows[rowIndex].data.breakdowns.findIndex((type) => type.key === customer.status);
          if (typeIndex !== -1) {
            rows[rowIndex].data.breakdowns[typeIndex].count += 1;
            rows[rowIndex].data.breakdowns[typeIndex].customerListRows?.push(customerListRow);
          }
        } else {
          rows.push({
            key: comparisonUid,
            color: getRandomColor(),
            data: {
              label,
              count: 1,
              breakdowns: CustomerStatuses.map((status) => ({
                key: status.key,
                count: customer.status === status.key ? 1 : 0,
                label: status.label,
                customerListRows: customer.status === status.key ? [customerListRow] : [],
              })),
            },
          });
        }
      });
      return rows;
    },
    [type]
  );

  const customTemplates: ISharedTableCustomCellTemplate[] = [
    {
      template: ReportBreakdownCustomCell,
      id: 'breakdown',
    },
  ];

  const tableColumns = [{ label: columnLabel, key: 'breakdown', templateId: 'breakdown', width: 100 }];

  const typeOptions = [
    {
      label: 'Lead Type',
      value: 'leadType',
    },
    {
      label: 'Organiser',
      value: 'organiser',
    },
  ];

  return (
    <>
      <PageHeader
        title='Hearing test outcome report'
        actions={[
          {
            key: 'typeSelector',
            element: (
              <div className='min-w-[260px]'>
                <p className='label-02 font-semibold mb-1 text-gray-500'>Report type</p>
                <Select<SelectOption>
                  defaultValue={typeOptions.find((option) => option.value === type)}
                  options={typeOptions}
                  onChange={(value) => setType(value?.value || 'leadType')}
                  isClearable={false}
                  isSearchable={false}
                />
              </div>
            ),
          },
        ]}
        showBack
      />
      <ReportBase
        tableColumns={tableColumns}
        processRows={processRows}
        getQueryOptions={(before, after) => ({
          beforeDate: before,
          afterDate: after,
          types: [AppointmentType.HOME_TEST, AppointmentType.SHOP_TEST],
        })}
        subscriber={AppointmentsApiService.subscribeToAppointments}
        totalLabel={'Total number of bookings:'}
        emptyText={'No appointments created during the selected time frame'}
        customTemplates={customTemplates}
        queryOrdering={['createdAt']}
      />
    </>
  );
};

export default HearingTestOutcome;
