export const AidType = {
  ESSENTIA: 'essentia',
  ESSENTIA_RECHARGEABLE: 'essentiaRechargeable',
  EVOLV_1100_AI: 'evolv1100Ai',
  EVOLV_1200_AI: 'evolv1200Ai',
  GENESIS_AI_16: 'genesisAi16',
  GENESIS_AI_20: 'genesisAi20',
  GENESIS_AI_24: 'genesisAi24',
  CENS: 'cens',
  SIGNATURE: 'signature',
};

export const AidTypes = [
  {
    label: 'Envy',
    value: AidType.ESSENTIA,
  },
  {
    label: 'Envy Rechargeable',
    value: AidType.ESSENTIA_RECHARGEABLE,
  },
  {
    label: 'Genesis AI 16',
    value: AidType.GENESIS_AI_16,
  },
  {
    label: 'Genesis AI 20',
    value: AidType.GENESIS_AI_20,
  },
  {
    label: 'Genesis AI 24',
    value: AidType.GENESIS_AI_24,
  },
  {
    label: 'CENS',
    value: AidType.CENS,
  },
  {
    label: 'Signature',
    value: AidType.SIGNATURE,
  },
];

export const getAidTypeFromKey = (key: string) => {
  return AidTypes.find((type) => type.value === key);
};

export const getAidTypeNameFromKey = (key: string) => {
  const type = AidTypes.find((type) => type.value === key);
  return type?.label ?? 'Unknown';
};
